import { ConstantsStore } from "../../stores/constants-store"
import { UserManager } from "oidc-client";
import { storeUserError, storeUser } from '../../actions/authActions'

const userManager = new UserManager(
  {
    ...ConstantsStore.env.identity,
    metadata: {
      ...ConstantsStore.env.metadataOIDC
    }
  }
  );

export function loadUserFromStorage() {
  return async dispatch => {
    try {
      let user = await userManager.getUser();
      if (!user) { return dispatch(storeUserError()) }

      dispatch(storeUser(user))
    } catch (e) {
      console.error(`User not found: ${e}`)
      dispatch(storeUserError())
    }
  }
}

export function signinRedirect() {
    return userManager.signinRedirect()
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signoutRedirect(idTokenHint) {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirect({ 'id_token_hint': idTokenHint });
  }
  
  export function signoutRedirectCallback() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirectCallback()
}

export default userManager