import { useMediaQuery } from "react-responsive";
import { ConstantsStore } from "../stores/constants-store"

export const useMediaQueryWrapper = () => {
    const mediaQuery = { 
        isMobile: useMediaQuery({ query: `(max-width: ${ConstantsStore.shared.mediumWidth})` }), 
        isDesktop: useMediaQuery({ query: `(min-width: ${ConstantsStore.shared.mediumWidth})` })
    };

    return mediaQuery;
};