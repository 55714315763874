import { Link } from "react-router-dom";

const MovieListLink = (props) => {

    const { isLoading, 
        isPosterLoaded, 
        pathname, 
        search, 
        posterUrl, 
        alternativePosterPath, 
        onLoad, 
        onClick, 
        innerHTML, 
        posterSkeleton, 
        titleSkeleton } = props;
        
    return (
        <Link className={'mobile-font'} to={{pathname: pathname, search: search}} onClick={() => onClick()}>
            { !isLoading && <>
            <img 
                  decoding='async'
                  className="movie-poster mt-3 pr-5" 
                  src={ posterUrl } 
                  alt="movie poster" style={{maxWidth: '5%', maxHeight: '5%'}}
                  onError={(e)=>{e.target.onerror = null; e.target.src=alternativePosterPath}}
                  onLoad={() => onLoad()}
                /> &nbsp;&nbsp; {innerHTML}</> }
            { (isLoading || !isPosterLoaded) && 
                    <div className='row'>
                        <div className='col-1'>
                          {posterSkeleton}
                        </div>
                        <div className='col-3 pt-4'>{titleSkeleton}</div>
                    </div> }
        </Link>
    )
}

export default MovieListLink;