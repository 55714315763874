import { WebStorageStateStore } from 'oidc-client';

const shared = {
    smallWidth: '576px',
    mediumWidth: '768px',
    largeWidth: '992px',
    extraWidth: '1200px',
    posterUrl: 'https://storage.googleapis.com/abominionfreebucket/cinescan-posters'
}

const devBaseUrls = {
    client: 'https://cinescan.abominion.net',
    movies: 'https://cinescan.movies.rpc.abominion.net',
    media: 'https://cinescan.media.abominion.net',
    identity: 'https://cinescan.identity.abominion.net'
}

const devControllers = {
    files: `${devBaseUrls.media}/Files`,
    account: `${devBaseUrls.identity}/Account`
}

const dev = {
    endpoints: {
        movies: `${devBaseUrls.movies}`,
        uploadChunk: `${devControllers.files}/Chunk`,
        uploadMoviesComplete: `${devControllers.files}/MoviesUploadComplete`,
        register: `${devControllers.account}/Register`
    },

    /* identityDev: {
        authority: "https://localhost:5002", //(string): The URL of the OIDC provider.
        client_id: "cinescan.client", //(string): Your client application's identifier as registered with the OIDC provider.
        redirect_uri: "http://localhost:3000/signin-oidc", //The URI of your client application to receive a response from the OIDC provider.
        login: "http://localhost:3000/login",
        automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
        loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
        silent_redirect_uri: "", //(string): The URL for the page containing the code handling the silent renew.
        post_logout_redirect_uri: "http://localhost:3000/signout-oidc", // (string): The OIDC post-logout redirect URI.
        audience: "https://cinescan.abominion.net", //is there a way to specific the audience when making the jwt
        responseType: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
        grantType: "password",
        scope: "openid movies.read movies.write", //(string, default: 'openid'): The scope being requested from the OIDC provider.
        webAuthResponseType: "id_token token",
    }, */

    identity: {
        authority: devBaseUrls.identity,
        client_id: "cinescan.client",
        redirect_uri: `${devBaseUrls.client}/signin-oidc`,
        response_type: "id_token token",
        scope: "openid profile movies.read movies.write",
        post_logout_redirect_uri: `${devBaseUrls.client}/signout-oidc`,
        end_session_endpoint: `${devBaseUrls.identity}/connect/endsession`,
        userStore: new WebStorageStateStore({ store: window.localStorage }) // userStore defaults to sessionStorage, which causes userManager to lose 
        // the user if a new tab is opened. Using localStorage prevents this
    },

    metadataOIDC: {
       issuer: devBaseUrls.identity,
       jwks_uri: devBaseUrls.identity + "/.well-known/openid-configuration/jwks",
       authorization_endpoint: "https://localhost:5002/connect/authorize",
       token_endpoint: "https://localhost:5002/connect/token",
       userinfo_endpoint: "https://localhost:5002/connect/userinfo",
       end_session_endpoint: "https://localhost:5002/connect/endsession",
       check_session_iframe: "https://localhost:5002/connect/checksession",
       revocation_endpoint: "https://localhost:5002/connect/revocation",
       introspection_endpoint: "https://localhost:5002/connect/introspect"
    },
}

const prodBaseUrls = {
    client: 'https://cinescan.abominion.net',
    movies: 'https://cinescan.movies.rpc.abominion.net',
    media: 'https://cinescan.media.abominion.net',
    identity: 'https://cinescan.identity.abominion.net'
}

const prodControllers = {
    files: `${prodBaseUrls.media}/Files`,
    account: `${prodBaseUrls.identity}/Account`
}

const prod = {
    endpoints: {
        movies: `${prodBaseUrls.movies}`,
        uploadChunk: `${prodControllers.files}/Chunk`,
        uploadMoviesComplete: `${prodControllers.files}/MoviesUploadComplete`,
        register: `${prodControllers.account}/Register`
    },

    identity: {
        authority: prodBaseUrls.identity,
        client_id: "cinescan.client",
        redirect_uri: `${prodBaseUrls.client}/signin-oidc`,
        response_type: "id_token token",
        scope: "openid profile movies.read movies.write",
        post_logout_redirect_uri: `${prodBaseUrls.client}/signout-oidc`,
        end_session_endpoint: `${prodBaseUrls.identity}/connect/endsession`,
        userStore: new WebStorageStateStore({ store: window.localStorage }) // userStore defaults to sessionStorage, which causes userManager to lose 
        // the user if a new tab is opened. Using localStorage prevents this
    },

    metadataOIDC: {
        issuer: 'http://cinescan.identity.abominion.net',
        jwks_uri: prodBaseUrls.identity + "/.well-known/openid-configuration/jwks",
        authorization_endpoint: prodBaseUrls.identity + "/connect/authorize",
        token_endpoint: prodBaseUrls.identity + "/connect/token",
        userinfo_endpoint: prodBaseUrls.identity + "/connect/userinfo",
        end_session_endpoint: prodBaseUrls.identity + "/connect/endsession",
        check_session_iframe: prodBaseUrls.identity + "/connect/checksession",
        revocation_endpoint: prodBaseUrls.identity + "/connect/revocation",
        introspection_endpoint: prodBaseUrls.identity + "/connect/introspect"
    }
}

export const ConstantsStore = process.env.NODE_ENV === 'development' ? { shared, env: dev } : { shared, env: prod };