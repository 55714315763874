import * as React from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import useConstant from 'use-constant';

export const useDebouncedSearch = (searchFunction) => {

    // Handle the input text state
    const [inputValue, setInputValue] = React.useState('');
  
    /* Note(Lorenzo): if you can't return values from the debounced function (e.g. gRPC callbacks, which is the case in this app) simply 
     add a target variable where to store values and use it in the function itself */ 
    const [searchResult, setSearchResult] = React.useState(''); 
  
    // Debounce the original search async function
    const debouncedSearchFunction = useConstant(() =>
      AwesomeDebouncePromise(searchFunction, 300)
    );
  
    // The async callback is run each time the text changes,
    // but as the search function is debounced, it does not
    // fire a new request on each keystroke
    useAsync(
      async () =>
          debouncedSearchFunction(inputValue)
      ,
      [debouncedSearchFunction, inputValue]
    );
  
    // Return everything needed for the hook consumer
    return {
      inputValue,
      setInputValue,
      searchResult,
      setSearchResult
    };
  };
