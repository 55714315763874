/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { MovieSearchClient } from "../../services/movie/movie_grpc_web_pb";
import { GetMovieRequest } from "../../services/movie/movie_pb";
import { useMediaQueryWrapper } from "../../hooks/useMediaQueryWrapper";
import { ConstantsStore } from '../../stores/constants-store';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MovieDetails = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const movieId = useRef(0);
  const [state, setState] = useState({
    movie: {
      id: 0,
      originalTitle: '',   
      formattedTitle: '',   
      translatedTitle: '',   
      director: '',   
      actors: '',   
      country: '',   
      year: '',   
      length: '',   
      category: '',   
      description: ''
  },
  isActorsStringTooLong: false,
  expandActors: false,
  posterLoaded: false
  });
  const posterUrl = `${ConstantsStore.shared.posterUrl}/${state.movie.id}.jpg`;
  const alternativePosterPath = process.env.PUBLIC_URL + "/movie-poster-placeholder.png";
  const client = new MovieSearchClient(ConstantsStore.env.endpoints.movies, null, null);
  const isMobile = useMediaQueryWrapper().isMobile;
  const isDesktop = useMediaQueryWrapper().isDesktop;

  function getMovieDetails(id) {
    var request = new GetMovieRequest();
    request.setId(id);

    client.getMovie(request, null, (err, response) => {
      if (err) {
        console.err(err);
        navigate('/');
      }
      else {
          let movieInfoArr = response.getMovie().array; 
          setState(previousState => ({...previousState,
            movie: {
              id: movieInfoArr[0],
              originalTitle: movieInfoArr[1],   
              formattedTitle: movieInfoArr[2],   
              translatedTitle: movieInfoArr[3],   
              director: movieInfoArr[4],   
              actors: movieInfoArr[8],   
              country: movieInfoArr[9],   
              year: movieInfoArr[10],   
              length: movieInfoArr[11],   
              category: movieInfoArr[12],   
              description: movieInfoArr[13],
              folder: movieInfoArr[16]
            },
            isActorsStringTooLong: movieInfoArr[8]?.length > 140
          }));
        }
      });
  }

  function getTruncatedActorsList() {
    return `${state.movie.actors.substring(0,115)}...`;
  }

  function toggleActorsList() {
    setState(previousState => ({...previousState, 
      expandActors: !previousState.expandActors}))
  }

  function setPosterLoaded() {
    setState(previousState => ({...previousState, 
      posterLoaded: true}))
  }

  useEffect(() => {
    movieId.current = +query.get("movie");

    if(!movieId.current) 
      navigate('/');
    else
      getMovieDetails(movieId.current);
    }, []);

  return (
    <>    
    { isDesktop && 
      <div className='row mt-5'>
        <div className='col-2'>
        </div>
        <div className='col-8'>
          <div className='row'>
            <div className='col-3'>
              {!state.posterLoaded && <Skeleton width={'17vw'} height={'100%'} duration={0.7} baseColor={'rgba(182, 137, 255, 0.664)'}/>}
              <img 
              decoding='async'
              className="movie-poster" 
              src={posterUrl} 
              alt="movie poster"
              onError={(e)=>{e.target.onerror = null; e.target.src=alternativePosterPath; e.target.style.height='100%';}}
              onLoad={() => setPosterLoaded()}
              />
            </div>
            <div className='movie-info-container col-9'>
              <ul style={{marginTop: '10%', listStyle: 'none'}}>
                <li><strong>Original title:</strong> {state.movie.originalTitle ? state.movie.originalTitle : state.movie.translatedTitle}</li>
                <li><strong>Translated title:</strong> {state.movie.translatedTitle ? state.movie.translatedTitle : state.movie.originalTitle}</li>
                <li><strong>Category:</strong> {state.movie.category}</li>
                <li><strong>Year:</strong> {state.movie.year}</li>
                <li><strong>Director:</strong> {state.movie.director}</li>
                <li><strong>Actors:</strong> {state.isActorsStringTooLong && !state.expandActors ? getTruncatedActorsList() : state.movie.actors}
                {state.isActorsStringTooLong && <span className='toggle-text' onClick={() => toggleActorsList()}>{state.expandActors ? 'Show less' : 'Show more'}</span>}</li>
                <li><strong>Country:</strong> {state.movie.country}</li>
                <li><strong>Length:</strong> {state.movie.length}</li>
                <li><strong>Ref. N°:</strong> {state.movie.folder}</li>
              </ul>
            </div>
          </div>
          <div className='row'>
            <div className='movie-description-container col'>
                <p className='m-3'><strong>Description:</strong> {state.movie.description}</p>
            </div>
          </div>
        </div>
        <div className='col-2'>
        </div>
      </div>
    }

    { isMobile && 
      <div className='row mt-3'>
      <div className='col'>
        <div className='row'>
          <div className='col text-center'>
            <img 
            className="movie-poster-mobile" 
            src={ !movieId.current ? alternativePosterPath : posterUrl} 
            alt="movie poster" 
            onError={(e)=>{e.target.onerror = null; e.target.src=alternativePosterPath}}
            onLoad={() => setPosterLoaded()}
            />
          </div>
        </div>
        <div className='row'>
          <div className='movie-info-container col'>
            <ul style={{marginTop: '5%', marginBottom: '5%', listStyle: 'none'}}>
              <li><strong>Original title:</strong> {state.movie.originalTitle ? state.movie.originalTitle : state.movie.translatedTitle}</li>
              <li><strong>Translated title:</strong> {state.movie.translatedTitle ? state.movie.translatedTitle : state.movie.originalTitle}</li>
              <li><strong>Category:</strong> {state.movie.category}</li>
              <li><strong>Year:</strong> {state.movie.year}</li>
              <li><strong>Director:</strong> {state.movie.director}</li>
              <li><strong>Actors:</strong> {state.isActorsStringTooLong && !state.expandActors ? getTruncatedActorsList() : state.movie.actors}
                {state.isActorsStringTooLong && <span className='toggle-text' onClick={() => toggleActorsList()}>{state.expandActors ? 'Show less' : 'Show more'}</span>}</li>
              <li><strong>Country:</strong> {state.movie.country}</li>
              <li><strong>Length:</strong> {state.movie.length}</li>
              <li><strong>Ref. N°:</strong> {state.movie.folder}</li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <div className='col movie-description-container'>
              <p className='m-3'><strong>Description:</strong> {state.movie.description}</p>
          </div>
        </div>
      </div>
    </div>
    }
    </>
  );
};

export default MovieDetails;
