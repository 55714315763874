import React, { useEffect, useRef, useState } from "react";
import { ProgressBar, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { ConstantsStore } from '../../stores/constants-store';


function UploadMovies() {
    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToUpload, setFileToUpload] = useState({})
    const [beginningOfTheChunk, setBeginningOfTheChunk] = useState(0)
    const chunkSize = 1048576 * 3;
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [progress, setProgress] = useState(0)
    const [fileGuid, setFileGuid] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)
    const showError = useRef(false);

    useEffect(() => {
        if (fileSize > 0) {
            fileUpload();
        }
    }, [fileToUpload, progress])

    const getFileContext = (e) => {
        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        setFileToUpload(_file)
        const _fileID = uuidv4() + "." + _file.name.split('.').pop();
        setFileGuid(_fileID)
    }

    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginningOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
        showError.current = false;
    }

    const fileUpload = () => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToUpload.slice(beginningOfTheChunk, endOfTheChunk);
            uploadChunk(chunk);
        }
    }

    const uploadChunk = async (chunk) => {
        try {
            const response = await axios.post(ConstantsStore.env.endpoints.uploadChunk, chunk, {
                params: {
                    id: counter + '',
                    fileName: fileGuid,
                },
                headers: { 'Content-Type': 'application/json; charset=UTF-8' }
            });

            const data = response.data;
            if (data.isSuccess) {
                setBeginningOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                if (counter == chunkCount) {
                    console.log('Process is complete, counter', counter)
                    await uploadCompleted();
                } else {
                    var percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                console.log('Error Occurred:', data.errorMessage)
                setShowProgress(false);
            }
        } catch (error) {
              console.log(error);
              showError.current = true;
              setShowProgress(false);
        }
    }

    const uploadCompleted = async () => {
        var formData = new FormData();
        formData.append('fileName', fileGuid);

        const response = await axios.post(ConstantsStore.env.endpoints.uploadMoviesComplete, {}, {
            params: {
                fileName: fileGuid,
            },
            data: formData,
        });
        console.log('response', response);
        const data = response.data;
        if (data.isSuccess) {
            setProgress(100);
        }
    }

    return (
        <>
        <div className="row my-4">
            <p className="text-white text-center">Use this form to upload a .zip file containing <b>only</b> movies posters and a .csv file containing movies info</p>
        </div>
        <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
                <div className="row mb-2">
                    <div className="text-center">
                        <input className='form-control' type="file" onChange={getFileContext}></input>
                    </div>
                </div>
                {showProgress && <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ display: showProgress ? "block" : "none", width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{`${progress.toFixed()}%`}</div>
                </div>}
                {showError.current && <p className="text-warning text-center font-weight-bold"><strong>An error occurred. Check file content, size and extension</strong></p>}
            </div>
            <div className="col-3"></div>
        </div>
        </>
    );
}
export default UploadMovies;