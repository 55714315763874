import * as React from 'react';
import { useState, useRef } from 'react';
import { MovieSearchClient } from "../../services/movie/movie_grpc_web_pb";
import { GetAutocompletedMoviesRequest } from "../../services/movie/movie_pb";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ConstantsStore } from '../../stores/constants-store';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';
import { useMediaQueryWrapper } from "../../hooks/useMediaQueryWrapper";
import { useEffect } from 'react';

export default function AsyncAutocomplete(props) {
  const [ suggestedMovies, setSuggestedMovies ] = useState([]);
  const navigate = useNavigate();
  const useSuggestedMoviesAsync = () => useDebouncedSearch(text => getAsyncSuggestions(text));
  const { inputValue, setInputValue } = useSuggestedMoviesAsync();
  const [ isLoading, setIsLoading ] = useState(false);
  const client = new MovieSearchClient(ConstantsStore.env.endpoints.movies, null, null);
  const isMobile = useMediaQueryWrapper().isMobile;
  const isDesktop = useMediaQueryWrapper().isDesktop;
  const isMobileSearchBarFocused = useRef(false);
  const [ mobileSearchBarClass, setMobileSearchBarClass ] = useState('container base-search');

  const override = css`
  margin-top: 5px;
`;

  function getAsyncSuggestions(search) {

    if(stringNullOrWhiteSpace(search)) {
      setSuggestedMovies([]);
      return;
    }

    setIsLoading(true);
    setInputValue(search);
    var request = new GetAutocompletedMoviesRequest();
    request.setSearch(search.toString());
    
    client.getAutocompletedMovies(request, null, (err, response) => {
        setIsLoading(false);

        if (err) {
          console.log(err);
          setSuggestedMovies([]);
        }
        else {
          setSuggestedMovies(!response || response.getSuggestedMoviesList()?.length === 0 ? [] : response.getSuggestedMoviesList()
          .map(movie => { return {id: movie.array[0], title: movie.array[2], year: movie.array[10]} }));
          };
      });
  }

  function search(event) {
    event.preventDefault();

    navigate({
      pathname: "movielist",
      search: `?${createSearchParams({
          search: event.target[0].value.toString()
      })}`
    })
  }

  const handleSearchBarMobile = (event) => {
    /* onBlur (which happens when leaving focus) prevents hyperlinks to trigger navigation.
    By default, though, some HTML elements are capable of gaining autofocus 
    and populating the relatedTarget property of a DOM event object, which contains an element that just gained focus. 
    (for a full list of these elements https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null). 
    I exploited this behaviour to prevent onBlur from triggering when clicking on an autosuggested movie link */
    if(event.relatedTarget != null) return; 

    isMobileSearchBarFocused.current = !isMobileSearchBarFocused.current;
    setMobileSearchBarClass(isMobileSearchBarFocused.current ? 'container base-search slide-on-top' : 'container base-search')
    props.onFocusSearchBar(isMobileSearchBarFocused.current);            
  }

  function stringNullOrWhiteSpace(text) {
    return !text || text.match(/^ *$/) !== null;
  }

  useEffect(() => {
    return () => { 
      setSuggestedMovies([]);
      setIsLoading(false);
    }
  }, [])

  return (
    <>
    { isDesktop && <div className='container base-search'>
      <div className='row' >
        <div className='col-2'></div>
        <div className='col text-center'>
          <form onSubmit={search}>
            <div className='form-group'>
              <input type="search" className='form-control' onChange={e => setInputValue(e.target.value)} placeholder="Search titles, actors or directors" />
            </div>
          </form>
          <ClipLoader loading={isLoading} size={40} css={override} color={'#ffffff'} />
          {suggestedMovies && (
          <div>
            <ul className='suggestions'>
              {suggestedMovies.map(movie => (
                <li key={movie.id}>
                  <Link to={{pathname: `/moviedetails`, search: `?movie=${movie.id}`}}>{movie.title} - {movie.year}</Link>
                </li>
              ))}
            </ul>
          </div>
          )}
        </div>
        <div className='col-2'></div>
      </div>
    </div> }

    { isMobile && <div className={ mobileSearchBarClass }>
      <div className='row' >
        <div className='col-1'></div>
        <div className='col text-center' onFocus={handleSearchBarMobile} onBlur={handleSearchBarMobile}>
          <form onSubmit={search}>
            <div className='form-group'>
              <input type="search" className='form-control' onChange={e => setInputValue(e.target.value)} placeholder="Search titles, actors or directors" />
            </div>
          </form>
          <ClipLoader loading={isLoading} size={40} css={override} color={'#ffffff'} />
          {suggestedMovies && isMobileSearchBarFocused.current && (
          <div>
            <ul className='suggestions'>
              {suggestedMovies.map(movie => (
                <li style={{background: 'white', listStyle: 'none', border: '1px solid black'}} key={movie.id}><Link to={{pathname: `/moviedetails`, search: `?movie=${movie.id}`}}>{movie.title} - {movie.year}</Link></li>
              ))}
            </ul>
          </div>
          )}
        </div>
        <div className='col-1'></div>
      </div>
    </div> }
    </>
  );
}
