export default function KofiButton({kofiId, text = "", backgroundColor}) {
    return (
        <div className="kofi-container">
            <a className="kofi-button" 
            title={text} 
            style={{backgroundColor: backgroundColor}} 
            href={`https://ko-fi.com/${kofiId}`} 
            target="_blank"  
            rel="noreferrer noopener external"> 
                <span className="kofitext">
                    <img className={"kofiimg " + (text.length > 0 ? "withtext" : "notext")} src="https://storage.ko-fi.com/cdn/cup-border.png" alt="Ko-fi donations" />{text}
                </span>
            </a>
        </div>
    )
}