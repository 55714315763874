import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';

export default function FullPagination({ activePage, onPageActiveChange, lastPage, pageSize, onPageSizeChange, totalCount, className }) {
  const pageSizes = [10, 20, 30, 50, 75, 100];

  const onFirst = () => {
    onPageActiveChange(1);
  };

  const onLast = () => {
    onPageActiveChange(lastPage);
  };

  const onNext = () => {
    onPageActiveChange(activePage + 1);
  };

  const onPrevious = () => {
    onPageActiveChange(activePage - 1);
  };

  const handleChangePageSize = (event) => {
    event.persist();
    let val = event.target.value;
    onPageSizeChange(val);
    onPageActiveChange(1);
  }

  const handleChangePage = (event) => {
    event.persist();
    let val = event.target.value;
    onPageActiveChange(val);
  }

  function renderFirstButton() {
    if (activePage == 1) {
      return <Pagination.First disabled />;
    }

    return <Pagination.First onClick={onFirst} />;
  }

  function renderLastButton() {
    if (activePage == lastPage) {
      return <Pagination.Last disabled />;
    }

    return <Pagination.Last onClick={onLast} />;
  }

  function renderPrevButton() {
    if (activePage == 1) {
      return <Pagination.Prev disabled />;
    }

    return <Pagination.Prev onClick={onPrevious} />;
  }

  function renderNextButton() {
    if (activePage == lastPage) {
      return <Pagination.Next disabled />;
    }
    
    return <Pagination.Next onClick={onNext} />;
  }

  function renderOptionsSelectPage() {
    const maxNumPages = lastPage + 1;

    let options = [];

    for(let page = 1; page < maxNumPages; page++) {
      options.push(<option selected={page == activePage} key={page} value={page}>{page}</option>)
    }

    return options;
  }

  return (
    <div className={`row ${className}`}>
      <div className='col-4 pt-3'>
        Numero di elementi: <b>{totalCount}</b>
      </div>
      <div className='col-8 align-right'>
        <Pagination className='mt-2 d-flex justify-content-end gap-2'>
          <Form.Label className='d-flex align-self-center my-0 mr-2'>Elementi per pagina</Form.Label>
          <Form.Control className='w-auto mr-3' as="select" onChange={handleChangePageSize} defaultValue={pageSizes.find(ps => ps == pageSize)}>
            {pageSizes.map((num) => (
              <option key={num} value={num}>{num}</option>
            ))}
          </Form.Control>
          <Form.Label className='d-flex align-self-center my-0 ml-2'>Pagina</Form.Label>
          <Form.Control className='w-auto ml-2' as="select" onChange={handleChangePage}>
            {renderOptionsSelectPage()}
          </Form.Control>
          <Form.Label className='d-flex align-self-center my-0 mx-2'>di</Form.Label>
          <Pagination.Item className='mr-4' disabled>{lastPage}</Pagination.Item>
          <div className="d-flex">
            {renderFirstButton()}
            {renderPrevButton()}
            {renderNextButton()}
            {renderLastButton()}
          </div>
        </Pagination>
      </div>
    </div>
  );
}