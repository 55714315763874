// source: src/protos/movie.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.GetAutocompletedMoviesRequest', null, global);
goog.exportSymbol('proto.GetAutocompletedMoviesResponse', null, global);
goog.exportSymbol('proto.GetMovieRequest', null, global);
goog.exportSymbol('proto.GetMovieResponse', null, global);
goog.exportSymbol('proto.GetMoviesRequest', null, global);
goog.exportSymbol('proto.GetMoviesResponse', null, global);
goog.exportSymbol('proto.Movie', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAutocompletedMoviesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAutocompletedMoviesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAutocompletedMoviesRequest.displayName = 'proto.GetAutocompletedMoviesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAutocompletedMoviesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetAutocompletedMoviesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetAutocompletedMoviesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAutocompletedMoviesResponse.displayName = 'proto.GetAutocompletedMoviesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMoviesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetMoviesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMoviesRequest.displayName = 'proto.GetMoviesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMoviesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetMoviesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetMoviesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMoviesResponse.displayName = 'proto.GetMoviesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMovieRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetMovieRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMovieRequest.displayName = 'proto.GetMovieRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMovieResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetMovieResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMovieResponse.displayName = 'proto.GetMovieResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Movie = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Movie, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Movie.displayName = 'proto.Movie';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAutocompletedMoviesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAutocompletedMoviesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAutocompletedMoviesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAutocompletedMoviesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAutocompletedMoviesRequest}
 */
proto.GetAutocompletedMoviesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAutocompletedMoviesRequest;
  return proto.GetAutocompletedMoviesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAutocompletedMoviesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAutocompletedMoviesRequest}
 */
proto.GetAutocompletedMoviesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAutocompletedMoviesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAutocompletedMoviesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAutocompletedMoviesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAutocompletedMoviesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search = 1;
 * @return {string}
 */
proto.GetAutocompletedMoviesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAutocompletedMoviesRequest} returns this
 */
proto.GetAutocompletedMoviesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetAutocompletedMoviesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAutocompletedMoviesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAutocompletedMoviesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAutocompletedMoviesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAutocompletedMoviesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestedMoviesList: jspb.Message.toObjectList(msg.getSuggestedMoviesList(),
    proto.Movie.toObject, includeInstance),
    search: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAutocompletedMoviesResponse}
 */
proto.GetAutocompletedMoviesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAutocompletedMoviesResponse;
  return proto.GetAutocompletedMoviesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAutocompletedMoviesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAutocompletedMoviesResponse}
 */
proto.GetAutocompletedMoviesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Movie;
      reader.readMessage(value,proto.Movie.deserializeBinaryFromReader);
      msg.addSuggestedMovies(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAutocompletedMoviesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAutocompletedMoviesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAutocompletedMoviesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAutocompletedMoviesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestedMoviesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Movie.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Movie suggested_movies = 1;
 * @return {!Array<!proto.Movie>}
 */
proto.GetAutocompletedMoviesResponse.prototype.getSuggestedMoviesList = function() {
  return /** @type{!Array<!proto.Movie>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Movie, 1));
};


/**
 * @param {!Array<!proto.Movie>} value
 * @return {!proto.GetAutocompletedMoviesResponse} returns this
*/
proto.GetAutocompletedMoviesResponse.prototype.setSuggestedMoviesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Movie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Movie}
 */
proto.GetAutocompletedMoviesResponse.prototype.addSuggestedMovies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Movie, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetAutocompletedMoviesResponse} returns this
 */
proto.GetAutocompletedMoviesResponse.prototype.clearSuggestedMoviesList = function() {
  return this.setSuggestedMoviesList([]);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.GetAutocompletedMoviesResponse.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAutocompletedMoviesResponse} returns this
 */
proto.GetAutocompletedMoviesResponse.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMoviesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMoviesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMoviesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMoviesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMoviesRequest}
 */
proto.GetMoviesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMoviesRequest;
  return proto.GetMoviesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMoviesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMoviesRequest}
 */
proto.GetMoviesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMoviesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMoviesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMoviesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMoviesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string search = 1;
 * @return {string}
 */
proto.GetMoviesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMoviesRequest} returns this
 */
proto.GetMoviesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.GetMoviesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetMoviesRequest} returns this
 */
proto.GetMoviesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 size = 3;
 * @return {number}
 */
proto.GetMoviesRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetMoviesRequest} returns this
 */
proto.GetMoviesRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetMoviesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMoviesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMoviesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMoviesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMoviesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestedMoviesList: jspb.Message.toObjectList(msg.getRequestedMoviesList(),
    proto.Movie.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMoviesResponse}
 */
proto.GetMoviesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMoviesResponse;
  return proto.GetMoviesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMoviesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMoviesResponse}
 */
proto.GetMoviesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Movie;
      reader.readMessage(value,proto.Movie.deserializeBinaryFromReader);
      msg.addRequestedMovies(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMoviesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMoviesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMoviesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMoviesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestedMoviesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Movie.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Movie requested_movies = 1;
 * @return {!Array<!proto.Movie>}
 */
proto.GetMoviesResponse.prototype.getRequestedMoviesList = function() {
  return /** @type{!Array<!proto.Movie>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Movie, 1));
};


/**
 * @param {!Array<!proto.Movie>} value
 * @return {!proto.GetMoviesResponse} returns this
*/
proto.GetMoviesResponse.prototype.setRequestedMoviesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Movie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Movie}
 */
proto.GetMoviesResponse.prototype.addRequestedMovies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Movie, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetMoviesResponse} returns this
 */
proto.GetMoviesResponse.prototype.clearRequestedMoviesList = function() {
  return this.setRequestedMoviesList([]);
};


/**
 * optional int32 total_count = 2;
 * @return {number}
 */
proto.GetMoviesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetMoviesResponse} returns this
 */
proto.GetMoviesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMovieRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMovieRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMovieRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMovieRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMovieRequest}
 */
proto.GetMovieRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMovieRequest;
  return proto.GetMovieRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMovieRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMovieRequest}
 */
proto.GetMovieRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMovieRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMovieRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMovieRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMovieRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.GetMovieRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetMovieRequest} returns this
 */
proto.GetMovieRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMovieResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMovieResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMovieResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMovieResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    movie: (f = msg.getMovie()) && proto.Movie.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMovieResponse}
 */
proto.GetMovieResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMovieResponse;
  return proto.GetMovieResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMovieResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMovieResponse}
 */
proto.GetMovieResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Movie;
      reader.readMessage(value,proto.Movie.deserializeBinaryFromReader);
      msg.setMovie(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMovieResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMovieResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMovieResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMovieResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMovie();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Movie.serializeBinaryToWriter
    );
  }
};


/**
 * optional Movie movie = 1;
 * @return {?proto.Movie}
 */
proto.GetMovieResponse.prototype.getMovie = function() {
  return /** @type{?proto.Movie} */ (
    jspb.Message.getWrapperField(this, proto.Movie, 1));
};


/**
 * @param {?proto.Movie|undefined} value
 * @return {!proto.GetMovieResponse} returns this
*/
proto.GetMovieResponse.prototype.setMovie = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetMovieResponse} returns this
 */
proto.GetMovieResponse.prototype.clearMovie = function() {
  return this.setMovie(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetMovieResponse.prototype.hasMovie = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Movie.prototype.toObject = function(opt_includeInstance) {
  return proto.Movie.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Movie} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Movie.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    originalTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    formattedTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    translatedTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    director: jspb.Message.getFieldWithDefault(msg, 5, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 6, ""),
    writer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    composer: jspb.Message.getFieldWithDefault(msg, 8, ""),
    actors: jspb.Message.getFieldWithDefault(msg, 9, ""),
    country: jspb.Message.getFieldWithDefault(msg, 10, ""),
    year: jspb.Message.getFieldWithDefault(msg, 11, ""),
    length: jspb.Message.getFieldWithDefault(msg, 12, ""),
    category: jspb.Message.getFieldWithDefault(msg, 13, ""),
    description: jspb.Message.getFieldWithDefault(msg, 14, ""),
    filePath: jspb.Message.getFieldWithDefault(msg, 15, ""),
    languages: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cartella: jspb.Message.getFieldWithDefault(msg, 17, ""),
    score: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Movie}
 */
proto.Movie.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Movie;
  return proto.Movie.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Movie} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Movie}
 */
proto.Movie.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslatedTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirector(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWriter(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setComposer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setActors(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLength(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguages(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCartella(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Movie.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Movie.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Movie} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Movie.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOriginalTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormattedTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTranslatedTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDirector();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWriter();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getComposer();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActors();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLength();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLanguages();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCartella();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getScore();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.Movie.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string original_title = 2;
 * @return {string}
 */
proto.Movie.prototype.getOriginalTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setOriginalTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string formatted_title = 3;
 * @return {string}
 */
proto.Movie.prototype.getFormattedTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setFormattedTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string translated_title = 4;
 * @return {string}
 */
proto.Movie.prototype.getTranslatedTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setTranslatedTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string director = 5;
 * @return {string}
 */
proto.Movie.prototype.getDirector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setDirector = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string producer = 6;
 * @return {string}
 */
proto.Movie.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string writer = 7;
 * @return {string}
 */
proto.Movie.prototype.getWriter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setWriter = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string composer = 8;
 * @return {string}
 */
proto.Movie.prototype.getComposer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setComposer = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string actors = 9;
 * @return {string}
 */
proto.Movie.prototype.getActors = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setActors = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country = 10;
 * @return {string}
 */
proto.Movie.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string year = 11;
 * @return {string}
 */
proto.Movie.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string length = 12;
 * @return {string}
 */
proto.Movie.prototype.getLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setLength = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string category = 13;
 * @return {string}
 */
proto.Movie.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string description = 14;
 * @return {string}
 */
proto.Movie.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string file_path = 15;
 * @return {string}
 */
proto.Movie.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string languages = 16;
 * @return {string}
 */
proto.Movie.prototype.getLanguages = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setLanguages = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string cartella = 17;
 * @return {string}
 */
proto.Movie.prototype.getCartella = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setCartella = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string score = 18;
 * @return {string}
 */
proto.Movie.prototype.getScore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.Movie} returns this
 */
proto.Movie.prototype.setScore = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


goog.object.extend(exports, proto);
