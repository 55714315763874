import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const persistedState = localStorage.getItem('reduxState')  // Solution using localStorage to prevent redux store to be cleaned on page refresh https://stackoverflow.com/questions/35305661/where-to-write-to-localstorage-in-a-redux-app
// ? JSON.parse(localStorage.getItem('reduxState'))           // Since I needed this to preserve the user from refresh or new tabs, but I chose to directly use localStorage as userStore (see constantStore.js), uncomment and use this logic if you need
// : {}                                                       // to preserve whole parts of Redux store from refresh or new tabs


// For a smart Redux boilerplate using hooks https://medium.com/@mendes.develop/introduction-on-react-redux-using-hooks-useselector-usedispatch-ef843f1c2561
function configureStore() {
  let store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

//  store.subscribe(()=>{
//    localStorage.setItem('reduxState', JSON.stringify(store.getState()))
//  });

  return store;
}

export default configureStore;