import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { MovieSearchClient } from "../../services/movie/movie_grpc_web_pb";
import { useLocation, Link } from "react-router-dom";
import { GetMoviesRequest } from "../../services/movie/movie_pb";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ConstantsStore } from '../../stores/constants-store';
import { useMediaQueryWrapper } from "../../hooks/useMediaQueryWrapper";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { saveScrollAction } from '../../actions/saveScrollAction';
import { saveQueryAction } from '../../actions/saveQueryAction';
import MovieListLink from '../shared/MovieListLink';
import { useDispatch } from 'react-redux';
import FullPagination from '../shared/Pagination';
import usePagination from '../../hooks/usePagination';

const MovieList = () => {
  const query = new URLSearchParams(useLocation().search);
  const client = new MovieSearchClient(ConstantsStore.env.endpoints.movies, null, null);
  const isMobile = useMediaQueryWrapper().isMobile;
  const isDesktop = useMediaQueryWrapper().isDesktop;
  const dispatch = useDispatch();
  const { activePage, setActivePage, pageSize, setPageSize, lastPage, setLastPage, totalCount, setTotalCount } = usePagination();

  const searchParams = useRef({
    searchText: '',
    // page: 1,
    // size: 20,
    // hasErrors: false,
    // movies: [],
    // totalMoviesFound: 0
  });

  const [state, setState] = useState({
    requestedMovies: [],
    isLoading: false,
    hasErrors: false,
    postersStatuses: []
  });

  const override = css`
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
`;

const alternativePosterPath = "../../../public/movie-poster-placeholder.png";

  function getMovies(searchText) {
    searchParams.current.searchText = searchText;
    setState(previousState => ({...previousState, isLoading: true}));
    setState(previousState => ({...previousState, hasErrors: false}));

    var request = new GetMoviesRequest();
    request.setSearch(searchText);
    request.setPage(activePage);
    request.setSize(pageSize);

    client.getMovies(request, null, (err, response) => {
      if (err) {
        setState(previousState => ({...previousState, hasErrors: true}));
        console.log(err);
      } else {
        let movies = response.getRequestedMoviesList().map(movie => {
          return {
            id: movie.array[0],
            originalTitle: movie.array[1],
            translatedTitle: movie.array[3],
            year: movie.array[10]
          }
        });

        let totalMovies = response.getTotalCount();

        setTotalCount(totalMovies);

        setState(previousState => ({...previousState, 
          requestedMovies: movies}));
      }
      
      setState(previousState => ({...previousState, isLoading: false}));
    });
  }

  const handleActivePageChange = (page) => {
    searchParams.current.page = page;
    setActivePage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  }

  function getFallbackTranslatedTitle(movie) {
    return `${movie.translatedTitle ? movie.translatedTitle : movie.originalTitle}` 
  }
  
  function getFallbackOriginalTitle(movie) {
    return `${movie.originalTitle ? movie.originalTitle : movie.translatedTitle}` 
  }
  
  function isPosterLoaded(movieId) {
    return state.postersStatuses[movieId]?.loaded;
  }
  
  function setPosterLoaded(movieId) {
    let postersStatusesCopy = [...state.postersStatuses];
    
    let posterStatus = {
      ...postersStatusesCopy[movieId],
      loaded: true
    }
    
    postersStatusesCopy[movieId] = posterStatus;
    
    setState(previousState => ({...previousState, 
      postersStatuses: postersStatusesCopy}));
  }

  function saveScroll(id){
    if(!id) return;
    dispatch(saveScrollAction(id));
  }

  useEffect(() => {
    let querySearch = query.get("search");

    if(querySearch) getMovies(querySearch);
    
    // if(isSameLastSearch(querySearch))
    
    return () => {
      dispatch(saveScrollAction(activePage));
      dispatch(saveQueryAction(querySearch));
      // props.savePageAction(state.page);
      // props.saveQueryAction(querySearch);
    }
  }, [activePage, pageSize]);

  useEffect(() => {
    setLastPage(Math.ceil(totalCount / pageSize));
  }, [totalCount])

  return (
        <> 
          { isMobile && !state.requestedMovies.length && !state.isLoading && 
          <div style={{marginTop: '45vh'}}><p className='text-center text-light'>No movies were found. Please, <Link to={{pathname: `/`}}>try again</Link> </p></div>}
          { isDesktop && state.requestedMovies.length && (
            <>
               <FullPagination 
                className={"text-white px-4 mt-2"}
                activePage={activePage}
                onPageActiveChange={handleActivePageChange}
                lastPage={lastPage}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                totalCount={totalCount}
              />
              <ul className={'requested-movies'}>
                { state.requestedMovies.map(movie => (
                  <li className="list-element" key={movie.id}>
                    <MovieListLink 
                    isLoading={state.isLoading}
                    isPosterLoaded={isPosterLoaded(movie.id)}
                    pathname={`/moviedetails`}
                    search={`?movie=${movie.id}`}
                    posterUrl={`${ConstantsStore.shared.posterUrl}/${movie.id}.jpg`}
                    alternativePosterPath={alternativePosterPath}
                    onLoad={() => setPosterLoaded(movie.id)}
                    onClick={() => saveScroll(movie.id)}
                    posterSkeleton={<Skeleton width={'80%'} height={'130px'} duration={0.7} baseColor={'rgba(182, 137, 255, 0.664)'}/>}
                    titleSkeleton={<Skeleton count={2} width={'20vw'} duration={0.7} baseColor={'rgba(182, 137, 255, 0.664)'}/>}
                    innerHTML={`${getFallbackTranslatedTitle(movie)} (${getFallbackOriginalTitle(movie)}) - ${movie.year}`} 
                    />
                  </li>
                ))}
              </ul>
              <FullPagination 
                className={"text-white px-4"}
                activePage={activePage}
                onPageActiveChange={handleActivePageChange}
                lastPage={lastPage}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                totalCount={totalCount}
              />
            </>
          )} 

      {isMobile && (
        <>
          {!state.requestedMovies.length && !state.isLoading &&
            <div style={{ marginTop: '45vh' }}><p className='text-center text-light'>No movies were found. Please, <Link to={{ pathname: `/` }}>try again</Link> </p></div>}

          <div className={state.isLoading ? 'parentDisable' : ''} width="100%">
            <div className='overlay-box'>
              <ClipLoader loading={state.isLoading} size={75} css={override} color={'#ffffff'} />
            </div>
          </div>

          {state.requestedMovies.length &&
            <>
              <FullPagination
                className={"text-white px-4"}
                activePage={activePage}
                onPageActiveChange={handleActivePageChange}
                lastPage={lastPage}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                totalCount={totalCount}
              />
              <ul className={'requested-movies'}>
                {state.requestedMovies.map(movie => (
                  <li className="list-element" key={movie.id}>
                    <div className="row mt-3">
                      <div className="col-3">
                        <Link className={'mobile-font'} to={{ pathname: `/moviedetails`, search: `?movie=${movie.id}` }}>
                          <img
                            decoding='async'
                            className="movie-poster"
                            src={`${ConstantsStore.shared.posterUrl}/${movie.id}.jpg`}
                            alt="movie poster" style={{ maxHeight: '15vh' }}
                            onError={(e) => { e.target.onerror = null; e.target.src = alternativePosterPath }}
                          />
                        </Link>
                      </div>
                      <div className="col-9 mobile-font">
                        <Link to={{ pathname: `/moviedetails`, search: `?movie=${movie.id}` }}> {getFallbackTranslatedTitle(movie)}<br></br>({getFallbackOriginalTitle(movie)})<br></br>{movie.year}  </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <FullPagination
                className={"text-white px-4"}
                activePage={activePage}
                onPageActiveChange={handleActivePageChange}
                lastPage={lastPage}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                totalCount={totalCount}
              />
            </>
          }
        </>
          )} 
        </>
    );
}

// --- Verbose use of Redux

// const mapStateToProps = state => ({
//   ...state
// });

// const mapDispatchToProps = dispatch => ({
//   saveScrollAction: (payload) => dispatch(saveScrollAction(payload)),
//   saveQueryAction: (payload) => dispatch(saveQueryAction(payload)),
//   savePageAction: (payload) => dispatch(savePageAction(payload)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(MovieList);

// ---

export default MovieList;