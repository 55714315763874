import { useState } from 'react'

const usePagination = () => {
	const [activePage, setActivePage] = useState(1);
	const [lastPage, setLastPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  return {
    activePage,
    setActivePage,
    lastPage,
    setLastPage,
    totalCount,
    setTotalCount,
    pageSize,
    setPageSize
  }
}

export default usePagination;