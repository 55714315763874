const initialState = {
    scrolledId: null,
    lastSearchPage: null,
    lastSearchQuery: null
};

export const userSelectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "saveScroll":
            return {
                ...state,
                scrolledId: action.payload
            };
        case "savePage":
            return {
                ...state,
                lastSearchPage: action.payload
            };
        case "saveQuery":
            return {
                ...state,
                lastSearchQuery: action.payload
            };
        default:
            return state;
    }
};