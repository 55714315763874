export const useUserRoles = (user) => {

    function ensureArray(value) {
        if (!Array.isArray(value)) {
          return [value];
        }
        return value;
    }

    return ensureArray(user?.profile.role);
};