/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = require('./movie_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.MovieSearchClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.MovieSearchPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetAutocompletedMoviesRequest,
 *   !proto.GetAutocompletedMoviesResponse>}
 */
const methodDescriptor_MovieSearch_GetAutocompletedMovies = new grpc.web.MethodDescriptor(
  '/MovieSearch/GetAutocompletedMovies',
  grpc.web.MethodType.UNARY,
  proto.GetAutocompletedMoviesRequest,
  proto.GetAutocompletedMoviesResponse,
  /**
   * @param {!proto.GetAutocompletedMoviesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetAutocompletedMoviesResponse.deserializeBinary
);


/**
 * @param {!proto.GetAutocompletedMoviesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GetAutocompletedMoviesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetAutocompletedMoviesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.MovieSearchClient.prototype.getAutocompletedMovies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/MovieSearch/GetAutocompletedMovies',
      request,
      metadata || {},
      methodDescriptor_MovieSearch_GetAutocompletedMovies,
      callback);
};


/**
 * @param {!proto.GetAutocompletedMoviesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetAutocompletedMoviesResponse>}
 *     Promise that resolves to the response
 */
proto.MovieSearchPromiseClient.prototype.getAutocompletedMovies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/MovieSearch/GetAutocompletedMovies',
      request,
      metadata || {},
      methodDescriptor_MovieSearch_GetAutocompletedMovies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetMoviesRequest,
 *   !proto.GetMoviesResponse>}
 */
const methodDescriptor_MovieSearch_GetMovies = new grpc.web.MethodDescriptor(
  '/MovieSearch/GetMovies',
  grpc.web.MethodType.UNARY,
  proto.GetMoviesRequest,
  proto.GetMoviesResponse,
  /**
   * @param {!proto.GetMoviesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetMoviesResponse.deserializeBinary
);


/**
 * @param {!proto.GetMoviesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GetMoviesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetMoviesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.MovieSearchClient.prototype.getMovies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/MovieSearch/GetMovies',
      request,
      metadata || {},
      methodDescriptor_MovieSearch_GetMovies,
      callback);
};


/**
 * @param {!proto.GetMoviesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetMoviesResponse>}
 *     Promise that resolves to the response
 */
proto.MovieSearchPromiseClient.prototype.getMovies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/MovieSearch/GetMovies',
      request,
      metadata || {},
      methodDescriptor_MovieSearch_GetMovies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetMovieRequest,
 *   !proto.GetMovieResponse>}
 */
const methodDescriptor_MovieSearch_GetMovie = new grpc.web.MethodDescriptor(
  '/MovieSearch/GetMovie',
  grpc.web.MethodType.UNARY,
  proto.GetMovieRequest,
  proto.GetMovieResponse,
  /**
   * @param {!proto.GetMovieRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetMovieResponse.deserializeBinary
);


/**
 * @param {!proto.GetMovieRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GetMovieResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetMovieResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.MovieSearchClient.prototype.getMovie =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/MovieSearch/GetMovie',
      request,
      metadata || {},
      methodDescriptor_MovieSearch_GetMovie,
      callback);
};


/**
 * @param {!proto.GetMovieRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetMovieResponse>}
 *     Promise that resolves to the response
 */
proto.MovieSearchPromiseClient.prototype.getMovie =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/MovieSearch/GetMovie',
      request,
      metadata || {},
      methodDescriptor_MovieSearch_GetMovie);
};


module.exports = proto;

