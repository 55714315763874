import Home from './components/features/Home';
import MovieDetails from './components/features/MovieDetails';
import MovieList from './components/features/MovieList';
import SigninOidc from './components/features/Signin-oidc';
import SignoutOidc from './components/features/Signout-oidc';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useEffect } from 'react';
import userManager, { loadUserFromStorage, signinRedirect, signoutRedirect } from './services/user/userService';
import AuthProvider from './utils/authProvider'
import { useSelector, useDispatch } from 'react-redux';
import UploadMovies from './components/features/UploadMovies';
import CookieBanner from 'react-cookie-banner/lib';
import { ConstantsStore } from './stores/constants-store';
import KofiButton from './components/core/KofiButton';
import { useMediaQueryWrapper } from './hooks/useMediaQueryWrapper';
import { useUserRoles } from './hooks/useUserRoles';

function App() {

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const isAdmin = useUserRoles(user).some(element => element === "Admin");
  const isDesktop = useMediaQueryWrapper().isDesktop;

  function signIn(user) {
    if(user) return;
    signinRedirect();
  }

  function signOut(user) {
    if(!user) return;
    signoutRedirect(user.id_token);
  }
  
  useEffect(() => {    
    dispatch(loadUserFromStorage())
  }, [])

  return (
    <AuthProvider userManager={userManager}>
      <div id="app" className={"d-flex flex-column"}>
        <Navbar bg="dark" expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="/"><span className={"gradient-logo-link"}>Cinescan</span></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/"><span className={"colored-nav-link"}>Home</span></Nav.Link>
                {(!user) && <Nav.Link onClick={() => signIn(user)}><span className={"colored-nav-link"}>Sign In</span></Nav.Link>}
                {(user) && <Nav.Link onClick={() => signOut(user)}><span className={"colored-nav-link"}>Sign Out</span></Nav.Link>}
                {(user && isAdmin) && <Nav.Link href="/uploadmovies"><span className={"colored-nav-link"}>Upload Movies</span></Nav.Link>}
                {(!user) && <Nav.Link href={ConstantsStore.env.endpoints.register}><span className={"colored-nav-link"}>Register</span></Nav.Link>}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <BrowserRouter>
          <div style={{minHeight: "100vh"}}>
            <Routes>
              <Route path="/movielist" element={<MovieList/>}/>
              <Route path="/moviedetails" element={<MovieDetails/>}/>
              <Route path="/signin-oidc" element={<SigninOidc/>} />
              <Route path="/signout-oidc" element={<SignoutOidc/>} />
              <Route path="/uploadmovies" element={<UploadMovies/>} />
              <Route path="/" element={<Home/>}/>
            </Routes>
          </div>
        </BrowserRouter>
        <KofiButton kofiId={'Z8Z7FWQFW'} text={isDesktop ? 'Buy me a coffee!' : ''} backgroundColor={'#17b42f'}></KofiButton>
        <CookieBanner
          dismissOnScroll={false}
          message="We use cookies to ensure user experience!"
          onAccept={() => {}}
          cookie="user-has-accepted-cookies" 
          styles={{
            banner: { backgroundColor: 'rgba(60, 60, 60, 0.8)', position: 'fixed', bottom: '0'},
          }}
        />
        
      </div>
    </AuthProvider>
  );
}

export default App;