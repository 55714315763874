import { useState } from 'react';
import AsyncAutocomplete from '../shared/AsyncAutocomplete'

const Home = () => {
    const [ logoClass, setLogoClass ] = useState('container-fluid col-12 logo'); 

    const handleToggleLogo = (isMobileSearchBarFocused) => {
        setLogoClass(isMobileSearchBarFocused ? 'container-fluid col-12 logo slide-out-up' : 'container-fluid col-12 logo');
    }

    return(
        <>
        <div className={ logoClass }>
                <AsyncAutocomplete onFocusSearchBar={handleToggleLogo}/>
        </div>
        </>
    );
}

export default Home;